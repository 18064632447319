import { CONTENT_LOADED } from './types'
// import { store } from '../store/store'
import api from '../api'

export const loadContent = () => dispatch => {
	return api.content
		.loadContent()
		.then(res => res)
		.then(res => {
			dispatch({
				type: CONTENT_LOADED,
				payload: res.data.data
			})
			return res
		})
		.catch(err => {
			console.error(err)
			console.error('Error loading Content')
		})
}
