import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { login } from '../../actions/userActions'
import { loadContent } from '../../actions/contentActions'
import LoginForm from '../forms/LoginForm'

class LoginPage extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string
	}

	constructor(props) {
		super(props)
		this.submit = this.submit.bind(this)
	}

	submit = data =>
		this.props
			.login(data)
			.then(() => this.props.loadContent())
			.then(() => this.props.history.push('/dashboard'))
	// submit = data => {

	// }

	render() {
		return (
			<div className="login_page">
				<div className="login">
					<LoginForm submit={this.submit} />
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect(
		null,
		{ login, loadContent }
	)(LoginPage)
)
