/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const middleware = applyMiddleware(thunk)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
	key: 'kokeshi_monitoring_v1',
	storage,
	whitelist: ['user', 'content']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore(rootReducer, composeEnhancers(middleware)
export const store = createStore(persistedReducer, composeEnhancers(middleware))

export const persistor = persistStore(store)
