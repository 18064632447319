import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'

const ChatDisplay = ({ messages }) => {
	let last_date = 0
	if (messages.length === 0)
		return (
			<div className="note">
				Please select a conversation on the left.
			</div>
		)
	return (
		<div className="chat_display">
			<span className="date">
				<Moment add={{ hours: 2 }} format="DD.MM.YYYY - HH:mm">
					{messages[0].created_on}
				</Moment>
			</span>
			{messages.map(entry => {
				const this_date = entry.created_on

				let diff = moment(this_date).diff(moment(last_date), 'seconds')

				if (last_date === 0) diff = 0
				last_date = entry.created_on
				return (
					<div key={entry.id + entry.user_id}>
						{diff > 300 && (
							<span className="date">
								<Moment
									add={{ hours: 2 }}
									format="DD.MM.YYYY - HH:mm"
								>
									{entry.created_on}
								</Moment>
							</span>
						)}
						{entry.user_query && entry.user_query.length > 0 && (
							<div className="chat_entry user_query">
								<span
									className={`user_query ${
										entry.matched_intent ===
										'Default Fallback Intent'
											? 'fallback'
											: ''
									}`}
								>
									{entry.user_query}
								</span>
							</div>
						)}
						{entry.action && entry.action.length > 0 && (
							<div className="chat_entry">
								<span className="action">{entry.action}</span>
							</div>
						)}
						{entry.kokeshi_answer &&
							entry.kokeshi_answer.length > 0 && (
								<div className="chat_entry kokeshi_answer">
									<span
										className={`kokeshi_answer ${
											entry.matched_intent ===
											'Default Fallback Intent'
												? 'fallback'
												: ''
										}`}
									>
										{entry.kokeshi_answer}
										<span className="date">
											{
												<Moment
													add={{ hours: 2 }}
													format="HH:mm"
												>
													{entry.created_on}
												</Moment>
											}
										</span>
									</span>
								</div>
							)}
					</div>
				)
			})}

			<span className="date">
				<Moment add={{ hours: 2 }} format="DD.MM.YYYY - HH:mm">
					{messages[messages.length - 1].created_on}
				</Moment>
			</span>
		</div>
	)
}

export default ChatDisplay
