import { combineReducers } from 'redux'
import uiReducer from './uiReducer'
import userReducer from './userReducer'
import contentReducer from './contentReducer'

const rootReducer = combineReducers({
	ui: uiReducer,
	user: userReducer,
	content: contentReducer
})

export default rootReducer
