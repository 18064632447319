import React from 'react'
import { connect } from 'react-redux'

import { refreshToken } from './actions/userActions'
import { loadContent } from './actions/contentActions'

class AppWrapper extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string,
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this.loadContent()
	}

	loadContent = () => {
		const { token } = this.props
		if (token) {
			this.props.refreshToken()
			this.props.loadContent()
		} else {
			setTimeout(this.loadContent, 2000)
		}
	}

	render() {
		return <React.Fragment>{this.props.children}</React.Fragment>
	}
}

const mapStateToProps = state => ({
	token: state.user.token
})

export default connect(mapStateToProps, { refreshToken, loadContent })(
	AppWrapper
)
