import {
	USER_LOGGED_IN,
	TOKEN_REFRESHED,
	USER_LOGGED_OUT
} from '../actions/types'

const initialState = {
	token: null
}

export default function(state = initialState, action) {
	switch (action.type) {
		case USER_LOGGED_IN:
			return { ...state, token: action.payload.token }
		case TOKEN_REFRESHED:
			return { ...state, token: action.payload.token }
		case USER_LOGGED_OUT:
			return { ...state, token: null }
		default:
			return state
	}
}
