import { USER_LOGGED_IN, TOKEN_REFRESHED, USER_LOGGED_OUT } from './types'
// import { store } from '../store/store'
import api from '../api'
import { store } from '../store/store'

export const login = data => dispatch => {
	return api.user
		.login(data)
		.then(res => res)
		.then(res => {
			dispatch({
				type: USER_LOGGED_IN,
				payload: res.data.data
			})
			return res
		})
}

export const logout = data => dispatch => {
	dispatch({
		type: USER_LOGGED_OUT
	})
}

export const refreshToken = () => dispatch => {
	return api.user
		.refreshToken()
		.then(res => res)
		.then(res => {
			dispatch({
				type: TOKEN_REFRESHED,
				payload: res.data.data
			})
			return res
		})
		.catch(err => {
			store.dispatch({
				type: USER_LOGGED_OUT
			})
		})
}
