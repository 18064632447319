import React from 'react'
import { connect } from 'react-redux'

import { logout } from '../actions/userActions'

class LogoutButton extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string
	}

	constructor(props) {
		super(props)
		this.logout = this.logout.bind(this)
	}

	logout = () => {
		const { logout } = this.props
		logout()
	}

	render() {
		return (
			<button
				className="logout_button"
				type="button"
				onClick={this.logout}
			>
				Logout
			</button>
		)
	}
}

export default connect(
	null,
	{ logout }
)(LogoutButton)
