import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'

import LoginPage from './components/pages/LoginPage'
import DashboardPage from './components/pages/DashboardPage'

import AppWrapper from './AppWrapper'

import './scss/_index.scss'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppWrapper>
          <div className="App">
            <BrowserRouter>
              <Switch>
                <Route path="/" exact component={LoginPage} />
                <Route path="/dashboard" exact component={DashboardPage} />
                <Route path="/dashboard/:user" component={DashboardPage} />
              </Switch>
            </BrowserRouter>
          </div>
        </AppWrapper>
      </PersistGate>
    </Provider>
  )
}

export default App
