import axios from 'axios'
import _ from 'lodash'
import { store } from './store/store'

const apiBase = 'https://api.one-squared.com/kokeshi/users/directus/public/_'

const token = store.getState().user.token

const getAuthHeader = () => {
	if (store.getState().user.token) {
		return {
			Authorization: `Bearer ${store.getState().user.token}`
		}
	}
	return {}
}

const api = {
	user: {
		login: data => {
			const formData = new FormData()
			formData.append('email', data.username)
			formData.append('password', data.password)

			return axios.post(`${apiBase}/auth/authenticate`, formData)
			// .then(res => {
			// 	console.log(res)
			// 	return res
			// })
		},
		refreshToken: () => {
			const formData = new FormData()
			formData.append('token', store.getState().user.token)

			return axios.post(`${apiBase}/auth/refresh`, formData)
			// .then(res => {
			// 	console.log(res)
			// 	return res
			// })
		}
	},
	content: {
		loadContent: () => {
			return axios
				.get(
					`${apiBase}/items/chat_activity?limit=10000&sort=-created_on`,
					{
						headers: {
							...getAuthHeader()
						}
					}
				)
				.then(res => {
					// console.log(res.data.data)
					return res
				})
		}
	}
}

export default api
