import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { login } from '../../actions/userActions'
import { loadContent } from '../../actions/contentActions'
// import RefreshButton from '../RefreshButton'
import UsersSidebar from '../UsersSidebar'
import ChatDisplay from '../ChatDisplay'
import LogoutButton from '../LogoutButton'

class DashboardPage extends React.Component {
	state = {
		users: []
	}

	static propTypes = {
		// name: React.PropTypes.string
	}

	constructor(props) {
		super(props)
		this.submit = this.submit.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		const { chat_activity } = props
		let users = []
		let messages
		const chat_activity_reversed = chat_activity.slice().reverse()
		chat_activity_reversed.map(entry => {
			// console.log(entry.user_id)
			if (!_.find(users, { id: entry.user_id })) {
				messages = _.filter(chat_activity, {
					user_id: entry.user_id
				})
				// console.log(messages)
				users.push({
					id: entry.user_id,
					nrMessages: messages.length,
					date: messages[messages.length - 1].created_on
				})
			}
		})
		// console.log(users)

		return { users }
	}

	submit = data => this.props.login(data).then(() => this.props.loadContent())
	// submit = data => {

	// }

	render() {
		const { users } = this.state
		const {
			user: { token },
			history,
			match
		} = this.props
		if (!token) {
			history.push('/')
		}

		const { chat_activity } = this.props
		let messages
		if (match.params.user) {
			messages = _.filter(chat_activity, {
				user_id: match.params.user
			})
		} else {
			// messages = chat_activity
			messages = []
		}
		// console.log(match)

		return (
			<div>
				<UsersSidebar users={users} activeUser={match.params.user} />
				<ChatDisplay messages={messages} />
				<LogoutButton />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	chat_activity: state.content.chat_activity,
	user: state.user
})

export default withRouter(connect(mapStateToProps, {})(DashboardPage))
