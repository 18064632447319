import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import InlineError from '../messages/InlineError'
import { Link } from 'react-router-dom'

class LoginForm extends React.Component {
	constructor(props) {
		super(props)
		this.onChange = this.onChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}

	state = {
		data: {
			username: '',
			password: ''
		},
		loading: false,
		errors: {}
	}

	onChange = e =>
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value },
			errors: { ...this.state.errors, [e.target.name]: false }
		})

	onSubmit = () => {
		const { submit } = this.props
		const { data } = this.state
		const errors = this.validate(data)
		this.setState({ errors })
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true })
			submit(data)
				.then(res => {
					this.setState({ loading: false })
					return res
				})
				.catch(err => {
					// console.error(err.response.data.code)
					this.setState({
						errors: { global: 'Please try again later.' } || {},
						loading: false
					})
				})
		}
	}

	validate = data => {
		const errors = {}
		if (!Validator.isEmail(data.username)) errors.username = 'Invalid email'
		if (!data.password) errors.password = "Can't be blank"

		return errors
	}

	render() {
		const { data, errors, loading } = this.state
		// const errors = {}

		// console.log(this.state)

		return (
			<Form onSubmit={this.onSubmit} loading={loading}>
				{errors.global && (
					<Message negative>
						<Message.Header>Something went wrong</Message.Header>
						<p>{errors.global}</p>
					</Message>
				)}
				<h2 className="align_center">Log In</h2>
				<Form.Field error={!!errors.username}>
					<input
						type="email"
						id="email"
						name="username"
						placeholder="Email"
						autoComplete="email"
						value={data.username}
						onChange={this.onChange}
					/>

					{errors.username && <InlineError text={errors.username} />}
				</Form.Field>
				<Form.Field error={!!errors.password}>
					<input
						type="password"
						id="password"
						name="password"
						placeholder="Password"
						value={data.password}
						autoComplete="current-password"
						onChange={this.onChange}
					/>
					{errors.password && <InlineError text={errors.password} />}
				</Form.Field>
				<Button primary>Log In</Button>
				{
					// <p className="or align_center">or</p>
					// <Button type="button" className="button">
					// 	Sign Up
					// </Button>
					// <Link to="/forgot_password">Forgot Password?</Link>
				}
				<div className="loading">
					<span>loading...</span>
				</div>
			</Form>
		)
	}
}

LoginForm.propTypes = {
	submit: PropTypes.func.isRequired
}

export default LoginForm
