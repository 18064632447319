import { CONTENT_LOADED } from '../actions/types'

const initialState = {
	chat_activity: []
}

export default function (state = initialState, action) {
	switch (action.type) {
		case CONTENT_LOADED:
			// console.log(action.payload)
			return {
				...state,
				// chat_activity: action.payload
				// chat_activity: [...state.chat_activity, ...action.payload]
				chat_activity: [...action.payload.reverse()]
			}
		default:
			return state
	}
}
