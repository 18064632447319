import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

export default class UsersSidebar extends React.Component {
	static propTypes = {
		users: PropTypes.arrayOf(
			PropTypes.shape({ id: PropTypes.string.isRequired })
		)
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { users, activeUser } = this.props
		return (
			<div className="UsersSidebar">
				{users.map(user => {
					if (user.nrMessages > 2)
						return (
							<Link
								to={`/dashboard/${user.id}`}
								key={user.id}
								className={`user ${
									activeUser === user.id ? 'active' : ''
								}`}
							>
								{/* user.id */}
								{
									<Moment
										add={{ hours: 2 }}
										format="DD.MM.YYYY"
									>
										{user.date}
									</Moment>
								}{' '}
								({user.nrMessages})
							</Link>
						)
				})}
			</div>
		)
	}
}
